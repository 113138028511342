import React from "react"
import { Link } from "gatsby"
import "../assets/fonts/font.css"
import { connect } from "react-redux"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';
import ShoppingContainer from '../components/shoppingcontainer';
import * as actionCreators from './../store/actions/index'
import phoneIcon from "../images/phone-icon.svg"
import chatIcon from "../images/chat-icon.svg"
import lineImage from "../images/line2.png"
import lineLeft from "../images/left-line.svg"
import lineRight from "../images/right-line.svg"
import Layout from "../components/layout"
import icon from "../images/vehical-info.svg"
import icon1 from "../images/your-info.svg"
import icon2 from "../images/customize-plan.svg"
import icon3 from "../images/create-account.svg"
import icon4 from "../images/payment-info.svg"
import icon5 from "../images/confirm-icon.svg"
import icon6 from "../images/repair1-icon.svg"
import icon7 from "../images/repair2-icon.svg"
import icon8 from "../images/repair3-icon.svg"
import icon9 from "../images/repair4-icon.svg"
import icon10 from "../images/repair5-icon.svg"
import image1 from '../images/covered/core.png';
import image2 from '../images/covered/comfort.png';
import image3 from '../images/covered/convenience.png';
import SEO from "../components/seo"
import mobileBanner from "../images/service-contract-mobile.svg"
import FactoryPopup from "../components/popupFactory";
import PopupStacks from "../components/popupStacks";
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupShow: false, 
      bestPricePopup: false,
      stacksPopup: false
    }
    if(props.location && props.location.state && props.location.state.isLogout){
      props.logout();
    }
  }

  render() {
    const {
      props
    } = this;

    return (
      <Layout rest={props} layoutType="main">
        <SEO title="Servicecontract.com - Home" keywords={[`warranty`, `online extended warranty`, `extended warranty`, `car warranty`]} />
        {/* --------------banner--------- */}
        {/* <section className="home">
          <div className="banner-inner">
            <img src={cloud} alt="" className="cloud-bg" />
            <div className="banner-bg">
              <img src={bannerImg} alt="" className="" />
            </div>
            
            <div className="container">
              <h1 className="mainTitle">We’re Changing the Way You <span>Protect</span> Your Vehicle</h1>
              <div className="mBanner">
                <img src={mobileBanner} alt="" className="" />
              </div>
              <p className="ultimate">Ultimate flexibility, low prices, entirely online.</p>
              <Link to="/vehicleInfo/" className="btn primary">Get your Price</Link>
            </div>
          </div>
        </section> */}


        <section className="simpbaner">
            <div className="siminer">
              <div className="simprow">
              <h1 className="simptitle">We’re Changing the Way You <span>Protect</span> Your Vehicle</h1>
              </div>
              <div className="simprow"><img src={mobileBanner} alt="" className="mobaner" /></div>
              <div className="simprow"><p className="simpdesc">Ultimate flexibility, low prices, entirely online.</p></div>
              <Link to="/vehicleInfo/" className="btn primary get-price">Get your Price</Link>
            </div>
        </section>


        {/* --------------- process-work-container--------- */}
        <section className="process-work-container">
          <div className="container">
            <h3 className="title">How the Process Works</h3>
            <p  className="subtitle">Our customized process allows you to buy coverage specifically for your needs and driving habits.</p>
            <div className="work-content">
              <div className="card first">
                <div className="header">
                  <span> <img src={icon} alt="Logo" /></span>

                </div>
                <h4>Vehicle Info</h4>
                <p>Providing your vehicle’s year, make, model, and mileage helps us narrow down your options.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon1} alt="Logo" /></span>
                </div>
                <h4>Your Info</h4>
                <p>Providing us your email and phone number (optional) allows you to save your quote and come back later.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon2} alt="Logo" /></span>
                </div>
                <h4>Customize Your Plan</h4>
                <p>We help you customize the plan to best fit your needs, driving habits, and budget.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon3} alt="Logo" /></span>
                </div>
                <h4>Create Account</h4>
                <p>Creating an account gives you the option to save multiple quotes and then buy your plan.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon4} alt="Logo" /></span>
                </div>
                <h4>Payment Info</h4>
                <p>Simply provide your payment information and select the terms that best fit your needs.</p>
              </div>
              <div className="card last">
                <div className="header">
                  <span> <img src={icon5} alt="Logo" /></span>
                </div>
                <h4>Confirm</h4>
                <p>Confirm your information and make sure you’ve selected the right plan.  We’ll do the rest!</p>
              </div>
            </div>
            {/* <div className="slider-point">
              <span className="active"></span>
              <span></span>
              <span></span>
            </div> */}
            <div className="btn-container">
              <a className="btn btn-outline" href={`tel:${'18008003629'}`}><img src={phoneIcon} alt="Logo" /> Call us</a>
              {/* <button className="btn btn-outline"><img src={phoneIcon} alt="Logo" /> Call us</button> */}
              {/* <button >Get Your Price</button> */}
              <Link className="btn primary get-price" to="/vehicleInfo/">Get Your Price</Link>
              <Link className="btn btn-outline" to="/contact"><img src={chatIcon} alt="Logo" /> Contact Us</Link>
            </div>
          </div>

        </section>

        <ShoppingContainer />
        <div className="down-img">
            <img className="left-line" src={lineLeft} alt="line" />
            <img className="right-line" src={lineRight} alt="line" />
          </div>
        <section className="monthly-sub-container">
          <div className="container">
            <h3 className="title">Killer Prices, Monthly Subscription</h3>
            <p className="subtitle">Choose your coverage and deductible.</p>
            <div className="subscription-card">
              <div className="card">
                <div className="inner-card">
                  <h4 className="lite-space">Powertrain</h4><p className="alas">as low as</p>
                  <p className="hide">Monthly, from<br /> </p>
                  
                  <p className="amount"><small className="small-1"> $</small>35<sup className="small-1">*</sup></p>
                  <p>per month</p>
                  {/* <button className="btn">Get Your Price</button> */}
                  <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>

                </div>

              </div>
              <div className="card">
                <div className="inner-card">
                  <h4>Enhanced </h4><p className="alas">as low as</p>
                  <p className="amount"><small className="small-1"> $</small>38<sup className="small-1">*</sup></p>
                  <p>per month</p>
                  {/* <button className="btn">Get Your Price</button> */}
                  <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>

                </div>

              </div>
              <div className="card">
                <div className="inner-card">
                  <h4>Almost<br /> Comprehensive </h4><p className="alas">as low as</p>
                  <p className="amount"><small  className="small-1">$</small>42<sup className="small-1">*</sup></p>
                  <p>per month</p>
                  {/* <button className="btn">Get Your Price</button> */}
                  <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>
                </div>

              </div>
              <div className="card">
                <div className="inner-card">
                  <h4 className="lite-space">Comprehensive </h4><p className="alas">as low as</p>
                  <p className="amount"><small  className="small-3">$</small>50<sup className="small-1">*</sup></p>
                  <p>per month</p>
                  {/* <button className="btn">Get Your Price</button> */}
                  <Link className="btn primary" to="/vehicleInfo/">Get Your Price</Link>
                </div>
 
              </div>
            </div>
            <p className="disclimer">* Actual prices vary based on your vehicle, mileage, and deductible chosen.  
            Please click <Link to="/vehicleInfo/">Get a Quote</Link> to get your exact prices.
            </p>
            <br/>
            <FactoryPopup />
          </div>
        </section>


        <section className="covered-container">
          <div className="container">
            <h3 className="title">What's Covered?</h3>
            <p className="subtitle">We think it should be simple: Core, Comfort, Convenience.*</p>
            <div className="covered-card">
              <div className="card first">
                <div className="inner-card">
                  <div className="header">
                    <img src={image1} alt="image1" />
                  </div>
                  <div className="card-body">
                    <h4>Core</h4>
                    <p>Core components are the things that make your car go and are typically the costliest to repair. Examples include your engine and transmission.</p>
                  </div>

                </div>
              </div>
              <div className="card">
                <div className="inner-card">
                  <div className="header">
                    <img src={image2} alt="image1" />
                  </div>
                  <div className="card-body">
                    <h4>Comfort</h4>
                    <p>Comfort components allow you to drive comfortably and include things like your air conditioning and suspension.</p>
                  </div>

                </div>
              </div>
              <div className="card last">
                <div className="inner-card">
                  <div className="header">
                    <img src={image3} alt="image1" />
                  </div>
                  <div className="card-body">
                    <h4>Convenience</h4>
                    <p>Convenience components make driving easy. <br />Things like navigation, back up cameras, and Bluetooth all fall under convenience items.</p>
                  </div>

                </div>
              </div>
            </div>
            {/* <div className="slider-point">
              <span className="active"></span>
              <span></span>
              <span></span>
            </div> */}
            {/* <button className="btn get-price-btn">Get your Price</button> */}
            <Link className="btn primary get-price-btn" to="/vehicleInfo">Get Your Price</Link>
          </div>
        </section>
        <section className="process-work-container extra-benefits">
          <div className="container">
            <h3 className="title">Extra Benefits</h3>
            <p className="subtitle">Extra benefits are things that make the repair process easier.</p>
            <div className="work-content">
              <div className="card">
                <div className="header">
                  <span> <img src={icon6} alt="Logo" /></span>
                </div>
                <p>Choose your repair shop.  If you don’t know one, call us, we’ll help!</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon7} alt="Logo" /></span>
                </div>
                <p>Roadside assistance is provided just in case you get stuck on the side of the road.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon8} alt="Logo" /></span>
                </div>
                <p>Unlike others, all of our coverages are offered on a monthly basis, so you’re not locked in! You can also modify your coverage whenever you want.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon9} alt="Logo" /></span>
                </div>
                <p>You choose the deductible that best fits your budget.</p>
              </div>
              <div className="card last">
                <div className="header">
                  <span> <img src={icon10} alt="Logo" /></span>
                </div>
                <p>If you’re car is stuck in the shop, we’ll take care of the rental car.<sup className="small-1">#1</sup></p>
              </div>
            </div>
            {/* <div className="slider-point">
              <span className="active"></span>
              <span></span>
              <span></span>
            </div> */}
            <div className="btn-container">
              {/* <button className="btn get-price-btn">Get Your Price</button> */}
              <Link className="btn primary get-price-btn" to="/vehicleInfo">Get Your Price</Link>
            </div>
          </div>
        </section>
        <PopupStacks />
        {/* <NewsLatter /> */}
        <section className="contact-container">
          <div className="container">
            <div className="copyright">
              <p>#1 If your vehicle experiences a breakdown and is inoperable or unsafe to drive and needs to be held for at least one day by a repair facility for a covered repair, we will reimburse you the cost to rent a vehicle from a licensed rental agency at a rate not to exceed thirty-five dollars ($35.00) per day.  Rental reimbursement is limited to a maximum of five (5) days per breakdown of the same covered part and an aggregate maximum of thirty (30) days as limited by the rental reimbursement maximum.</p>
              <p>#2 Subject to the terms and conditions of the plan.</p>
            </div>
          </div>
        </section>
        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
      
    );
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);


// export default 
